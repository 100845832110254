import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/root/app.module'
import { environment } from './environments/environment'

if (environment.production) {
    enableProdMode()
}

/* eslint no-console: ["error", { allow: ["warn"] }] */
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.warn(err))
